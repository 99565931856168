import { Consent } from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';
import { END_OF_DAY_TIME, getDate, getUTCDate } from './time.helper';

const quoteEmailReminderconsent = 'EMAIL_QUOTE_REMINDER';

export default class GenerateConsent {
  static emptyConsent(): Omit<Consent, 'usages'> {
    return {
      expireDate: `${getUTCDate(getDate(30, { ...END_OF_DAY_TIME }))}`,
      createdDate: `${getUTCDate(new Date())}`,
    };
  }

  static quoteEmailReminder(): Consent {
    return {
      ...GenerateConsent.emptyConsent(),
      usages: [
        {
          type: quoteEmailReminderconsent,
          isAgentExclusive: false,
          version: 1,
        },
      ],
    };
  }
}

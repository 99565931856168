import { AxiosResponse } from 'axios';
import { merge } from 'lodash';
import parseDataToBookingManagement from '../../components/BookingWidget/BookingManagment/bookingManagment.mapper';
import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';
import {
  BookingConfig,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { BookingQuoteResponse } from '../../components/BookingWidget/BookingQuoteManagment/bookingQuoteResponse.interface';
import {
  BookingDataEnum,
  BookingDataResponse,
  BookingManagement,
  BookingManagementEnum,
} from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';
import useApi from './api.service';

async function saveBooking(
  apiProxyUrl: string,
  bookingSteps: BookingStepsContextState,
  bookingConfigData: BookingConfig,
  quoteResponse: BookingQuoteResponse,
  bookingDataResponse: BookingDataResponse | null,
): Promise<any> {
  const bookingData: BookingManagement = parseDataToBookingManagement(
    bookingSteps,
    bookingConfigData,
    quoteResponse,
    bookingDataResponse,
  );
  /* eslint-disable react-hooks/rules-of-hooks */
  const API = useApi(bookingConfigData);

  if (bookingDataResponse) {
    // eslint-disable-next-line no-param-reassign
    delete bookingDataResponse[BookingManagementEnum.BookingData][
      BookingDataEnum.ExpireDate
    ];
    const mergedData = merge({}, bookingDataResponse, bookingData);
    const bookingId =
      mergedData[BookingManagementEnum.BookingData][BookingDataEnum.Id];
    const url = `${apiProxyUrl}/booking/${bookingId}`;

    const medicalDisclaimerConsent =
      bookingSteps[BookingStepValueEnum.QuotationInformation]?.[
        BookingSubStepValueEnum.MedicalDisclaimerConsent
      ];

    if (medicalDisclaimerConsent) {
      mergedData.consents = [
        {
          usages: [
            {
              type: 'MEDICAL_DISCLAIMER_CONSENT',
              version: 1,
              isAgentExclusive: false,
            },
          ],
        },
        ...(bookingDataResponse[BookingManagementEnum.Consents] || []),
      ];
    }
    const payload = mergedData;
    const response: AxiosResponse = await API.put(url, payload);
    if (response.headers['x-amzn-remapped-authorization']) {
      localStorage.setItem(
        'token',
        response.headers['x-amzn-remapped-authorization'].replace(
          'Bearer ',
          '',
        ),
      );
    }
    return response.data;
  }

  const url = `${apiProxyUrl}/booking`;
  const payload = parseDataToBookingManagement(
    bookingSteps,
    bookingConfigData,
    quoteResponse,
    bookingDataResponse,
  );

  const response: AxiosResponse = await API.post(url, payload);
  const anonymousToken = response.headers['x-amzn-remapped-authorization'];
  if (anonymousToken) {
    localStorage.setItem('anonymousToken', anonymousToken);
  }
  return response.data;
}

export default saveBooking;

interface Time {
  hour: number;
  min: number;
  sec: number;
}

export const END_OF_DAY_TIME = { hour: 23, min: 59, sec: 59 } as const;

export const getUTCDate = (date: Date): Date =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );

export const getDate = (daysAhead: number, time?: Time): Date => {
  const date = new Date();
  date.setDate(date.getDate() + daysAhead);

  if (time) {
    date.setHours(time.hour, time.min, time.sec);
  }

  return date;
};
